<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" label="标签名称" name="name">
            <a-input v-model:value="formState.name" placeholder="请输入标签名称"></a-input>
          </a-form-item>
          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请输入" style="width: 200px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row>
          <a-col :span="18">
            <a-button v-permission="['film_label_add']" type="primary" @click="onAddTag">新增标签</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['film_label_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['film_label_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['film_label_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-permission="['film_label_del']" @click="onDelete(record)">
                      <a-menu-item>
                        刪除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="showModal" :title="modelRef.id ? '修改标签' : '添加标签'" @ok="onSubmit">
        <a-spin :spinning="loading">
          <a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{ span: 5 }" :wrapperCol="{span: 16}">
            <a-form-item label="标签名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input v-model:value="modelRef.name" placeholder="请输入标签名称"/>
            </a-form-item>
            <a-form-item label="标签简介" name="description">
              <a-input v-model:value="modelRef.description" placeholder="请输入标签简介"/>
            </a-form-item>
            <a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
              <a-input v-model:value="modelRef.sort" placeholder="请输入优先级"/>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import {deleteFilmTag, getFilmTagList, saveFilmTag, updateFilmTag} from "../../../service/modules/film";
export default {
  components: { Icon },
  data() {
    return {
      id: 0,
      isEdit: false,
      loading: false,
      searchData: {},
      showModal: false,
      modelRef: {},
      formState: {},
      list: [],
      columns: [{
        title: '标签名称',
        dataIndex: 'name',
      }, {
        title: '标签简介',
        dataIndex: 'description',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        width: 150,
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onAddTag(){
      this.modelRef = {};
      this.showModal = true;
      this.isEdit = false;
    },
    onEdit(item){
      this.showModal = true;
      this.modelRef = JSON.parse(JSON.stringify(item));
      this.isEdit = true;
      this.id = item.id;
    },
    async onSubmit(){
      this.$refs.addForm.validateFields().then(async () => {
        this.loading = true;
        let postData = JSON.parse(JSON.stringify(this.modelRef))
        try {
          let ret;
          if(this.isEdit) {
            postData.id = this.id;
            ret = await updateFilmTag(postData);
          } else {
            ret = await saveFilmTag(postData);
          }
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.modelRef = {};
            this.getData();
            this.showModal = false;
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    onDelete(item) {
      this.$confirm({
        title: '提示',
        content: '确定删除吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await deleteFilmTag({
              id: item.id
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onDisabled(item){
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret;
            if(item.isDisabled) {
              ret = await updateFilmTag({
                id: item.id,
                isDisabled: 0
              })
            } else {
              ret = await updateFilmTag({
                id: item.id,
                isDisabled: 1
              })
            }
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getFilmTagList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
